import React, { useState } from "react"
import { connect as connectFela } from "react-fela"

import slugify from "utilities/slugify"
import ArticleCard from "components/ArticleCard"
import Container from "components/container"
import fluidValues from "utilities/fluidValues"
import fluidDimensions from "utilities/fluidDimensions"
import calc from "utilities/calc"
import imageUrlFor from "utilities/imageUrlFor"
import Vimeo from '@u-wave/react-vimeo';
import { PlayButton } from "components/logos"
import colors, { getHexColor } from "theme/colors"
import Modal from "components/Modal"

const Video = connectFela(() => ({
  placeholder: {
    backgroundColor: getHexColor('Flow', '500'),
    ...fluidDimensions({height: [213, 411]}),
    ...fluidValues({marginBottom: [32, 48]}),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    width: '70%',

    phoneOnly: {
      width: '100%'
    }
  },
  playButton: {
    onHover:{
      '& circle': {
        fill: '#000000',
      },
      '& path': {
        fill: getHexColor('Flow', '500'),
      }
    }
  },
}))(
  ({
    rules,
    styles,
    videoId
  }) => {
  const [playing, play] = useState(false)
  return  <>
            <div className={styles.placeholder}>
              <PlayButton extend={{styles: rules.playButton}} onClick={() => play(true)} />
            </div>
            <Modal extend={{modal: rules.modal}} opened={playing} close={() => play(false)}>
              <Vimeo
                autoplay={playing}
                className={styles.video}
                color={colors.colorRoot600.replace(/^#/, '')}
                paused={!playing}
                video={videoId}
                responsive />
            </Modal>
          </>
 })

const ArticleImage = ({image, className}) => {
  return <div className={className}>
    <div style={{width: '100%', paddingBottom: (100/(548/411)) + "%"}}></div>
    <img alt={''} src={imageUrlFor(image).width(548).height(411).url()} />
  </div>
}

const MediumArticleCard = ({
  rules,
  styles,
  article: {
    featuredimage,
    image,
    ...article
  }
}) => {
  const isVideo = article.videoId
  const isImage = (featuredimage || image)
  return <Container noPadding extend={{styles: rules.styles}} id={slugify(article.cardTitle || article.title)}>
    <div className={styles.wrap}>
      {isImage && <ArticleImage className={styles.image} image={featuredimage || image} />}
      {isVideo && <Video videoId={article.videoId} />}

      <ArticleCard
        extend={{styles: rules.article}}
        articleIntro={article._rawIntro}
        {...article} />
    </div>
  </Container>
}

/*
 */
const styles = (props) => ({
  wrap: {
    extend: [
      {
        condition: props.rightOne,
        style: {
          '@media(min-width: 700px)': {
            marginLeft: calc(12, 50, 600, 1440) + ' !important',
          }
        }
      },
      {
        condition: !props.rightOne,
        style: {
          '@media(min-width: 700px)': {
            marginRight: calc(12, 50, 600, 1440) + ' !important',
          },
        }
      }
    ]
  },
  styles: {
    '@media(max-width: 768px)': {
      paddingRight: 0,
    },

    extend: {
      condition: props.rightOne,
      style: {
        tabletLandscapeAndUp: {
          marginRight: calc(24, 50, 900, 1440) + ' !important',
        }
      }
    }
  },
  article: {
    maxWidth: 'unset',
    width: 'unset',

    phoneOnly: {
      paddingRight: 20,
      paddingLeft: 20,
    }
  },
  image: {
    /* flex: '1 1 auto', */
    position: 'relative',
    width: '100%',
    ...fluidDimensions({height: [213, 411]}),
    ...fluidValues({marginBottom: [32, 48]}),

    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
      opacity: 1,
      transition: 'opacity 500ms ease 0s',
    }

  }
})

export default connectFela(styles)(MediumArticleCard)
