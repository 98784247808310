import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"

import ArticleCard from "components/ArticleCard"
import EventArticleCard from "components/EventArticleCard"
import BigArticleCard from "components/BigArticleCard"
import MediumArticleCard from "components/MediumArticleCard"
import Container from "components/container"
import IntroBlock from 'components/IntroBlock'
import Layout from "components/layout"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import LoadingAnim from "components/LoadingAnim"

const IdeasBlock = ({
  pageContext: { country, pageSize },
  data,
  styles,
  rules,
  path,
}) => {
  const {
    sanityBlogPage: { title, header, meta, featured },
    allSanityArticle: { edges: nodes }
  } = data
  const articles = nodes.filter(({node}) => !featured.some(({id}) => id === node.id))
  let loading = false

  return (
    <Layout
      pageColor={colors.colorPattern50}
      linkColor={colors.colorPattern200}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={country}
    >
    {header && <IntroBlock
                 header
                 bgColor={colors.colorPattern100}
                 extend={{styles: rules.header, image: rules.headerImage}}
                 title={header.title}
                 copy={header._rawCopy}
                 link={header.link}
                 color={(header.fontcolor !== 'Light' ? colors.colorConsole500 : colors.colorCanvas50)}
                 image={header.image}
                 category={header.category} />}

    <Container flex noPadding extend={{styles: rules.articlesContainer}}>
      {featured && <Container flex extend={{styles: rules.featured}}>
        {featured.map((article, index) => {
          switch(index) {
            case 0:
              return (<BigArticleCard
                        extend={{styles: rules.bigArticle}}
                        key={index}
                        article={article} />)
            case 1:
            case 2:
            default:
              return (<MediumArticleCard
                        extend={{styles: rules.demiArticle}}
                        key={index}
                        rightOne={index === 2 }
                        article={article} />)
        }})}
      </Container>}

      <Container flex extend={{styles: rules.articles}}>
        {loading && <LoadingAnim extend={{styles: rules.loading}}/>}
        {!loading && articles &&
         articles.map(({node: article}, index) => {
           if ( article.articleType === 'Event') {
             return (<EventArticleCard
                       extend={{styles: rules.eventArticle}}
                       key={index}
                       article={article} />)
           }

           return (<ArticleCard
                     extend={{styles: rules.singleArticle}}
                     key={index}
                     title={article.title}
                     author={article.author}
                     published={article.published}
                     cardIntro={article.cardintro}
                     videoId={article.videoId}
                     articleIntro={article._rawIntro}
                     excerpt={article.excerpt}
                     cardTitle={article.cardtitle}
                     articleType={article.articleType}
                     image={article.featuredimage || article.image}
                     slug={article.slug} />)
        })}
        {!loading && articles.length > pageSize && <div className={styles.loadMoreButtonContainer}>
        </div>}
      </Container>
    </Container>
  </Layout>)}

const styles = () => ({
  loadMoreButtonContainer: {
    display: 'flex',
    flex: '1 0 100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  loading: {
    margin: '0 auto',
    display: 'block',
    paddingTop: 0,
    ...fluidValues({paddingBottom: [60, 120]}),
  },
  loadMoreButton: {
    display: 'block',
    margin: '10 40',
    maxWidth: 334,
  },
  header: {
    zIndex: 11,
  },
  headerImage: {
    phoneOnly: {
      display: 'none',
    }
  },
  articlesContainer: {
    paddingBottom: 60,
    ...fluidValues({paddingTop: [0, 0]}),
    tabletLandscapeAndUp: {
      extend: {
        ...fluidValues({paddingTop: [60, 120]})
      }
    }
  },
  articles: {
    maxWidth: 1600,
    margin: '0 auto',
    justifyContent: 'space-between',

    phoneOnly: {
      ...fluidValues({paddingLeft: [0, 0], paddingRight: [0, 0]}),
    },
  },
  featured: {
    maxWidth: 1600,
    margin: '0 auto',
    justifyContent: 'space-between',

    phoneOnly: {
      ...fluidValues({paddingLeft: [0, 0], paddingRight: [0, 0]}),
    },
  },
  filters: {
    maskImage: 'linear-gradient(to right, transparent, black 20px, black 90%, transparent)',
    maxWidth: 1061,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: -60,
    paddingBottom: 41,
    paddingLeft: 10,
    paddingRight: 60,
    zIndex: 300,
    position: 'relative',
    overflow: 'hidden',
    cursor: ['move', 'grab', '-moz-grab', '-webkit-grab'],

    phoneOnly: {
      paddingBottom: 44,
    },
    onActive: {
      cursor: ['grabbing', '-moz-grabbing', '-webkit-grabbing'],
    }
  },
  active: {
    whiteSpace: 'nowrap',
    position: 'relative',
    marginLeft: 60,
    userSelect: 'none',
    cursor: 'pointer',

    onAfter: {
      backgroundColor: '#FFFFFF',
      content: "''",
      display: 'block',
      height: 6,
      left: 0,
      position: 'absolute',
      top: 54,
      width: '100%',
    }
  },
  filter: {
    whiteSpace: 'nowrap',
    marginLeft: 60,
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
  },
  bigArticle: {
    ...fluidValues({marginBottom: [60, 120]}),
    marginLeft: 0,
  },
  demiArticle: {
    ...fluidValues({marginBottom: [60, 120]}),
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    flex: '1 1 100%',
    maxWidth: '100%',

    '@media(min-width: 700px)': {
      flex: '1 1 42%',
      maxWidth: '50vw',
    },
  },
  eventArticle: {
    ...fluidValues({marginTop: [60, 60], marginBottom: [60, 120]}),
    marginLeft: 0,
    paddingRight: 0,
    flex: '1 1 100%',
    maxWidth: '100%',
  },
  singleArticle: {
    ...fluidValues({marginBottom: [60, 120]}),
    marginLeft: 0,
    paddingRight: 0,
    flex: '1 1 100%',
    maxWidth: '100%',

    '@media(min-width: 700px)': {
      flex: '0 1 48%',
    },

    '@media(min-width: 1000px)': {
      flex: '0 1 32%',
    },
  },
  container: {
    phoneOnly: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
})

const Ideas = connectFela(styles)(IdeasBlock)
export default Ideas

/* IDEAS PAGE DATA QUERIED AT BUILD TIME */
export const query = graphql`
  query($pageSize: Int, $country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityBlogPage(countrySet: { eq: $country }) {
      title
      tags
      slug {
        current
      }
      meta {
        title
        description
        other {
          property
          content
        }
      }
      header {
        title
        _rawCopy
        category
        fontcolor
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 1440)
          }
        }
      }
      featured {
        ...articleCard
      }
    }

    allSanityArticle(
      filter: {countrySet: { eq: $country }},
      sort: {fields: published, order: DESC},
      limit: $pageSize) {
      edges {
        node {
        ...articleCard
        }
      }
    }
  }
`
